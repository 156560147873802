export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Circuits d'exception en Afrique, Amérique, Asie & Orient, Pacifique, et en Europe. Contactez nos spécialistes pour un voyage sur mesure."},{"name":"format-detection","content":"telephone=no"},{"name":"author","content":"Les Maisons du Voyage"},{"name":"publisher","content":"Les Maisons du Voyage"},{"hid":"og:title","property":"og:title","content":"Voyage sur mesure, circuit et sejour | Les Maisons du Voyage"},{"hid":"og:description","property":"og:description","content":"Circuits d'exception en Afrique, Amérique, Asie & Orient, Pacifique, et en Europe. Contactez nos spécialistes pour un voyage sur mesure."},{"property":"og:site_name","content":"Les Maisons du Voyage"},{"property":"og:site_url","content":"https://www.lesmaisonsduvoyage.com"},{"property":"og:locale","content":"fr_FR"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"preconnect","href":"https://cms-lmdv.twic.pics"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap"}],"style":[],"script":[],"noscript":[],"title":"Les Maisons du Voyage","htmlAttrs":{"lang":"fr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false