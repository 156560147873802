import { defineStore } from 'pinia';

export const useWindow = defineStore('window', {
  state: () => ({
    width: 0,
    scroll: 0
  }),
  actions: {
    changeWidth(width: number) {
      this.width = width;
    },
    changeScroll(scroll: number) {
      this.scroll = scroll;
    }
  }
});
